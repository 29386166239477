import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import { storeToRefs } from "pinia";

const routes: Array<RouteRecordRaw> = [
  // Dashboard
  {
    path: "/",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_dashboard",
        component: () => import("../views/dashboard/MainDashboardView.vue"),
        meta: {
          is_public: true,
        },
      },
    ],
  },
  // Shop
  {
    path: "/shop",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_shop",
        component: () => import("../views/shop/ShopManagementView.vue"),
        meta: {
          permission: "view_shop",
        },
      },
      {
        path: "create",
        name: "create_shop",
        component: () => import("../views/shop/CreateShopView.vue"),
        meta: {
          permission: "create_shop",
        },
      },
      {
        path: "detail/:code",
        name: "view_shop_detail",
        component: () => import("../views/shop/ShopDetailView.vue"),
        meta: {
          permission: "view_shop_detail",
        },
      },
      {
        path: "edit/:code",
        name: "edit_shop",
        component: () => import("../views/shop/EditShopView.vue"),
        meta: {
          permission: "view_shop_detail",
        },
      },
    ],
  },
  // Staff
  {
    path: "/staff",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_staff",
        component: () => import("../views/staff/StaffManagement.vue"),
        meta: {
          permission: "view_user",
        },
      },
      {
        path: "dashboard/:id",
        name: "view_staff_dashboard",
        component: () => import("../views/staff/StaffDashboardView.vue"),
        meta: {
          permission: "view_user_detail",
        },
      },
      {
        path: "create",
        name: "create_staff",
        component: () => import("../views/staff/StaffCreateView.vue"),
        meta: {
          permission: "create_user",
        },
      },
      {
        path: "edit/:id?",
        name: "edit_staff",
        component: () => import("../views/staff/StaffEditView.vue"),
        meta: {
          permission: "view_user_detail",
        },
      },
    ],
  },
  // Product and Price
  {
    path: "/product-price",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_products_and_price",
        component: () =>
          import("@/views/products-and-price/ProductPriceView.vue"),
        meta: {
          permission: "view_product_price",
        },
      },
      {
        path: "create",
        name: "create_products_and_price",
        component: () =>
          import("@/views/products-and-price/ProductPriceCreate.vue"),
        meta: {
          permission: "create_product_price",
        },
      },
    ],
  },
  // Price Announce
  {
    path: "/price-announce",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_price_announce",
        component: () => import("@/views/price-announce/PriceAnnounceView.vue"),
        meta: {
          permission: "view_price_announce",
        },
      },
    ],
  },
  // Order
  {
    path: "/order",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "sale",
        name: "view_order",
        component: () => import("../views/order/MainOrderManageMentView.vue"),
        meta: {
          permission: "view_order",
        },
      },
      {
        path: "sale/create",
        name: "create_order",
        component: () => import("../views/order/OrderCreateView.vue"),
        meta: {
          permission: "create_order",
        },
      },
      {
        path: "sale/:code/edit",
        name: "edit_order",
        component: () => import("../views/order/OrderEditView.vue"),
        meta: {
          permission: "create_order",
        },
      },
      {
        path: "approve",
        name: "view_order_approve",
        component: () => import("@/views/order/MainOrderApprovalView.vue"),
        meta: {
          permission: "update_order_approve",
        },
      },
      {
        path: "ticket",
        name: "view_order_ticket",
        component: () =>
          import("../views/order/ticket/MainOrderTicketView.vue"),
        meta: {
          permission: "view_order",
        },
      },
      {
        path: "ticket/create",
        name: "create_order_ticket",
        component: () =>
          import("../views/order/ticket/CreateOrderTicketView.vue"),
        meta: {
          permission: "create_order",
        },
      },
      {
        path: "ticket/use/:code",
        name: "use_order_ticket",
        component: () => import("../views/order/ticket/UseOrderTicketView.vue"),
        meta: {
          permission: "create_order",
        },
      },
      {
        path: "promotion",
        name: "view_order_promotion",
        component: () =>
          import("@/views/promotion/PromotionManagementView.vue"),
        meta: {
          permission: "view_product_promotion",
        },
      },
      {
        path: "promotion/create",
        name: "create_order_promotion",
        component: () => import("@/views/promotion/CreatePromotionView.vue"),
        meta: {
          permission: "create_product_promotion",
        },
      },
      {
        path: "promotion/approve",
        name: "view_order_promotion_approve",
        component: () =>
          import("../views/order/promotion/MainOrderPromotionView.vue"),
        meta: {
          permission: "view_product_promotion_detail",
        },
      },
      {
        path: "credit-note",
        name: "view_order_credit_note",
        component: () =>
          import("../views/order/credit-note/MainCreditManageMentView.vue"),
        meta: {
          permission: "view_order_credit_note",
        },
      },
      {
        path: "credit-note/create",
        name: "create_order_credit_note",
        component: () =>
          import("../views/order/credit-note/CreditCreateView.vue"),
        meta: {
          permission: "create_order_credit_note",
        },
      },
    ],
  },

  //Material invoice
  {
    path: "/material",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "order",
        name: "view_material_invoice",
        component: () =>
          import("../views/material-invoice/MaterialDashboardView.vue"),
        meta: {
          permission: "view_material_invoice",
        },
      },
      {
        path: "order/create/domestic",
        name: "create_domestic_material_invoice",
        component: () =>
          import("../views/material-invoice/CreateDomesticMaterial.vue"),
        meta: {
          permission: "create_material_invoice",
        },
      },
      {
        path: "order/create/foreign",
        name: "create_foreign_material_invoice",
        component: () =>
          import("../views/material-invoice/CreateForeignMaterial.vue"),
        meta: {
          permission: "create_material_invoice",
        },
      },
      {
        path: "order/edit/:code",
        name: "edit_material_invoice",
        component: () =>
          import("../views/material-invoice/EditMaterialInvoice.vue"),
        meta: {
          permission: "create_material_invoice",
        },
      },
    ],
  },
  // Stock Cart
  {
    path: "/stockcart",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_stockcart",
        component: () => import("../views/stockcart/MainStockCartView.vue"),
        meta: {
          permission: "view_warehouse_summary",
        },
      },
      {
        path: "activity",
        name: "view_stockcart_activity_log",
        component: () => import("../views/stockcart/ActivityLogView.vue"),
        meta: {
          permission: "view_warehouse_transaction",
        },
      },
      {
        path: "product/:id",
        name: "view_stockcart_product",
        component: () => import("../views/stockcart/StockCartProductsView.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "product/edit",
        name: "edit_stockcart_product",
        component: () =>
          import("../views/stockcart/StockCartProductsEditView.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "product/:id/store/:transaction_id/:map",
        name: "store_stockcart_product",
        component: () =>
          import("../views/stockcart/StockCartProductsStoreView.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "product/transfer",
        name: "transfer_stockcart_product",
        component: () =>
          import("../views/stockcart/StockCartProductsTransferView.vue"),
        meta: {
          public: true,
        },
      },
    ],
  },
  // Setting
  {
    path: "/settings",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "bank",
        name: "view_setting_bank",
        component: () => import("../views/setting/bank/BankView.vue"),
        meta: {
          permission: "create_bank",
        },
      },
      {
        path: "bank/create",
        name: "create_setting_bank",
        component: () => import("../views/setting/bank/BankCreateView.vue"),
        meta: {
          permission: "create_bank",
        },
      },
      {
        path: "bank/edit/:id",
        name: "edit_setting_bank",
        component: () => import("../views/setting/bank/BankEditView.vue"),
        meta: {
          permission: "update_bank_detail",
        },
      },
      {
        path: "bankaccount",
        name: "view_setting_bank_account",
        component: () => import("../views/setting/bank/BankAccountView.vue"),
        meta: {
          permission: "create_bank_account",
        },
      },
      {
        path: "bankaccount/create",
        name: "create_setting_bank_account",
        component: () =>
          import("../views/setting/bank/BankAccoutCreateView.vue"),
        meta: {
          permission: "create_bank_account",
        },
      },
      {
        path: "bankaccount/edit/:id",
        name: "edit_setting_bank_account",
        component: () =>
          import("../views/setting/bank/BankAccountEditView.vue"),
        meta: {
          permission: "update_bank_account_detail",
        },
      },
      {
        path: "product",
        name: "view_setting_product",
        component: () => import("../views/setting/product/ProductView.vue"),
        meta: {
          permission: "create_product",
        },
      },
      {
        path: "product/create",
        name: "create_setting_product",
        component: () =>
          import("../views/setting/product/CreateProductView.vue"),
        meta: {
          permission: "create_product",
        },
      },
      {
        path: "product/edit/:id",
        name: "edit_setting_product",
        component: () => import("../views/setting/product/EditProductView.vue"),
        meta: {
          permission: "update_procuct_detail",
        },
      },
      {
        path: "package",
        name: "view_setting_package",
        component: () => import("../views/setting/packages/PackagesView.vue"),
        meta: {
          permission: "create_product_package",
        },
      },
      {
        path: "package/create",
        name: "create_setting_package",
        component: () =>
          import("../views/setting/packages/PackagesCreateView.vue"),
        meta: {
          permission: "create_product_package",
        },
      },
      {
        path: "package/edit/:id",
        name: "edit_setting_package",
        component: () =>
          import("../views/setting/packages/PackagesEditView.vue"),
        meta: {
          permission: "update_product_package_detail",
        },
      },
      {
        path: "product-category",
        name: "view_setting_product_category",
        component: () =>
          import("../views/setting/product-category/ProductsCategoryView.vue"),
        meta: {
          permission: "create_product_category",
        },
      },
      {
        path: "product-category/create",
        name: "create_setting_product_category",
        component: () =>
          import(
            "../views/setting/product-category/ProductCategoryCreateView.vue"
          ),
        meta: {
          permission: "create_product_category",
        },
      },
      {
        path: "product-category/edit/:id",
        name: "edit_setting_product_category",
        component: () =>
          import(
            "../views/setting/product-category/ProductCategoryEditView.vue"
          ),
        meta: {
          permission: "update_product_category_detail",
        },
      },
      {
        path: "zone",
        name: "view_setting_zone",
        component: () => import("../views/setting/zone/ZoneView.vue"),
        meta: {
          permission: "create_zone",
        },
      },
      {
        path: "zone/create",
        name: "create_setting_zone",
        component: () => import("../views/setting/zone/ZoneCreateView.vue"),
        meta: {
          permission: "create_zone",
        },
      },
      {
        path: "zone/edit/:id",
        name: "edit_setting_zone",
        component: () => import("../views/setting/zone/ZoneEditView.vue"),
        meta: {
          permission: "update_zone_detail",
        },
      },
      {
        path: "supplier",
        name: "view_setting_supplier",
        component: () => import("../views/setting/supplier/SupplierView.vue"),
        meta: {
          permission: "create_supplier",
        },
      },
      {
        path: "supplier/create",
        name: "create_setting_supplier",
        component: () =>
          import("../views/setting/supplier/SupplierCreateView.vue"),
        meta: {
          permission: "create_supplier",
        },
      },
      {
        path: "supplier/edit/:id",
        name: "edit_setting_supplier",
        component: () =>
          import("../views/setting/supplier/SupplierEditView.vue"),
        meta: {
          permission: "update_supplier_detail",
        },
      },
      {
        path: "permission",
        name: "view_setting_permission",
        component: () =>
          import("../views/setting/permission/PermissionView.vue"),
        meta: {
          permission: "create_role",
        },
      },
    ],
  },
  // Profile
  {
    path: "/profile",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_profile",
        component: () => import("../views/profile/AccountProfileView.vue"),
        meta: {
          permission: "view_user_detail",
        },
      },
      {
        path: "edit",
        name: "edit_profile",
        component: () => import("../views/profile/AccountEditProfileView.vue"),
        meta: {
          permission: "update_user_detail",
        },
      },
      {
        path: "edit/password",
        name: "edit_profile_password",
        component: () => import("../views/profile/AccountEditProfileView.vue"),
        meta: {
          permission: "update_user_detail",
        },
      },
    ],
  },
  // Auth
  {
    path: "/auth",
    component: () => import("../Templates/LoginTemplate.vue"),
    children: [
      {
        path: "login",
        name: "view_login_page",
        component: () => import("../views/auth/LoginView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "forgot_password",
        name: "view_forgot_password",
        component: () => import("../views/auth/ForgotPasswordView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "check_email",
        name: "view_check_email",
        component: () => import("../views/auth/CheckEmailView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "change_password",
        name: "view_change_password",
        component: () => import("../views/auth/ChangePasswordView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "verify_otp",
        name: "view_verify_otp",
        component: () => import("../views/auth/VerifyOtpView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "line/callback",
        name: "view_line_callback",
        component: () => import("../views/auth/LineCallbackView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "callback",
        name: "view_auth_callback",
        component: () => import("../views/auth/CallbackView.vue"),
        meta: {
          is_public: true,
        },
      },
    ],
  },
  // Raw Material
  {
    path: "/material",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "stock",
        name: "view_material_movement_management",
        component: () =>
          import("../views/material-stock/MaterialStockView.vue"),
        meta: {
          permission: "view_warehouse_summary",
        },
      },
      {
        path: "stock/:id",
        name: "view_material_movement_detail",
        component: () =>
          import("../views/material-stock/MaterialStockDetailView.vue"),
      },
      {
        path: "stock/activity",
        name: "view_material_stock_log",
        component: () =>
          import("../views/material-stock/MaterialStockLogView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stock/:id/store/:transaction_id/:map",
        name: "view_material_stock_store",
        component: () =>
          import("../views/material-stock/MaterialStockStoreView.vue"),
      },
      {
        path: "stock/:id/edit",
        name: "view_material_stock_edit",
        component: () =>
          import("../views/material-stock/MaterialStockEditView.vue"),
      },
      {
        path: "stock/:id/transfer",
        name: "view_material_stock_transfer",
        component: () =>
          import("../views/material-stock/MaterialStockTransferView.vue"),
      },
      {
        path: "stock/:id/bag",
        name: "view_material_stock_changebag",
        component: () =>
          import("../views/material-stock/MaterialStockChangeBag.vue"),
      },
      {
        path: "stock/:id/damage/:wh_id",
        name: "view_material_stock_damage",
        component: () =>
          import("../views/material-stock/MaterialStockDamageView.vue"),
      },
      {
        path: "movement",
        name: "view_order_material",
        component: () => import("../views/material-stock/MainStock.vue"),
        meta: {
          permission: "view_material_invoice_approve",
        },
      },
      {
        path: "goods_receive",
        name: "view_goods_receive",
        component: () => import("../views/goods-receive/GoodsReceiveView.vue"),
        meta: {
          permission: "view_goods_received_approve",
        },
      },
    ],
  },
  // Material Bulk
  {
    path: "/material/requisition",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "stock",
        name: "view_material_requisition_stock",
        component: () =>
          import("@/views/material-bulk/material/MaterialRequisitionView.vue"),
        meta: {
          permission: "create_product_mix",
        },
      },
      {
        path: ":id",
        name: "view_material_requisition_detail",
        component: () =>
          import("@/views/material-bulk/material/MaterialRequisitionSack.vue"),
      },
      {
        path: ":id/create",
        name: "view_material_requisition_create",
        component: () =>
          import(
            "@/views/material-bulk/material/MaterialRequisitionCreate.vue"
          ),
      },
      {
        path: "log",
        name: "view_material_requisition_log",
        component: () =>
          import(
            "@/views/material-bulk/material/MaterialRequisitionLogView.vue"
          ),
      },
    ],
  },
  // Material Mix
  {
    path: "/material/mix",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "stock",
        name: "view_material_mix_stock",
        component: () =>
          import("@/views/material-bulk/mix/MaterialBulkView.vue"),
        meta: {
          permission: "create_product_mix",
        },
      },
      {
        path: ":id",
        name: "view_material_mix_detail",
        component: () =>
          import("@/views/material-bulk/mix/MaterialBulkSack.vue"),
      },
      {
        path: ":id/create",
        name: "view_material_mix_create",
        component: () =>
          import("@/views/material-bulk/mix/MaterialBulkCreate.vue"),
      },
      {
        path: "log",
        name: "view_material_mix_log",
        component: () =>
          import("@/views/material-bulk/mix/MaterialBulkLog.vue"),
      },
      {
        path: ":id/requisition/:wh_id",
        name: "view_material_mix_requisition",
        component: () =>
          import("@/views/material-bulk/mix/MaterialBulkRequisition.vue"),
      },
    ],
  },
  // Statistics
  {
    path: "/statistics",
    component: () => import("../Templates/BaseTemplate.vue"),
    children: [
      {
        path: "",
        name: "view_statistics",
        component: () => import("../views/statistic/StatisticsView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "top_sale",
        name: "view_statistics_topsale",
        component: () => import("../views/statistic/TopsaleView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-by-group",
        name: "view_statistics_stats_by_group",
        component: () => import("../views/statistic/StatisticsByZoneView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-ticket",
        name: "view_statistics_ticket",
        component: () => import("../views/statistic/StatTicketView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-by-category",
        name: "view_statistics_stats_by_category",
        component: () =>
          import("../views/statistic/StatisticsByCategoryView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-by-zone",
        name: "view_statistics_stats_by_zone",
        component: () => import("../views/statistic/StatSaleByZoneView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "shop-rank",
        name: "view_statistics_shop_ranking",
        component: () => import("../views/statistic/ShopRankingView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-credit-overdue",
        name: "view_statistics_credit_overdue",
        component: () => import("../views/statistic/StatCreditOverdueView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-incoming-overdue",
        name: "view_statistics_incoming_overdue",
        component: () =>
          import("../views/statistic/StatIncomingOverdueView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-sale-overdue",
        name: "view_statistics_sale_overdue",
        component: () => import("../views/statistic/StatSaleOverdueView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-credit",
        name: "view_statistics_credit",
        component: () => import("../views/statistic/StatCreditView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-damaged",
        name: "view_statistics_stats_damaged",
        component: () => import("../views/statistic/StatDamagedReport.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "stats-creditnote",
        name: "view_statistics_credit_note",
        component: () => import("../views/statistic/StatCreditNoteView.vue"),
        meta: {
          is_public: true,
        },
      },
      {
        path: "sales-by-shop",
        name: "view_statistics_sales_by_shop",
        component: () => import("../views/statistic/SalesByShop.vue"),
        meta: {
          is_public: true,
        },
      },
    ],
  },
  //Report
  {
    path: "/report",
    component: () => import("../Templates/ReportTemplate.vue"),
    children: [
      {
        path: "sale-order",
        name: "view_report_sale_order",
        component: () => import("../views/report/SaleOrder.vue"),
      },
      {
        path: "invoice",
        name: "view_report_invoice",
        component: () => import("../views/report/InvoiceBill.vue"),
      },
      {
        path: "receipt",
        name: "view_report_receipt",
        component: () => import("../views/report/ReceiptBill.vue"),
      },
      {
        path: "decrease-debt",
        name: "view_report_decrease_debt",
        component: () => import("../views/report/DecreaseDebt.vue"),
      },
      {
        path: "increase-debt",
        name: "view_report_increase_debt",
        component: () => import("../views/report/IncreaseDebt.vue"),
      },
      {
        path: "bill-order",
        name: "view_report_bill_order",
        component: () => import("../views/report/BillOrder.vue"),
      },
      {
        path: "requisition",
        name: "view_report_requisition",
        component: () => import("../views/report/RequisitionBill.vue"),
      },
      {
        path: "booking",
        name: "view_report_booking",
        component: () => import("../views/report/BookingProduct.vue"),
      },
      {
        path: "promotion",
        name: "view_report_promotion",
        component: () => import("../views/report/PromotionProgram.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    router.resolve({ name: "view_login_page" }).path, // /auth/login
  ];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  if (authRequired && !user.value) {
    authStore.returnUrl = to.fullPath;
    return router.resolve({ name: "view_login_page" }).path;
  }
});

export default router;
