import { defineStore } from "pinia";
import { ref } from "vue";
import $http from "@/plugins/axios";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(JSON.parse(localStorage.getItem("user")));
  const token = ref(JSON.parse(localStorage.getItem("token")));
  const returnUrl = ref("");

  async function loginWithMobile(mobile, password) {
    const tokenRes = await $http.post(`v1/auth/token/`, {
      username: mobile,
      password: password,
    });
    this.token = tokenRes.data;
    localStorage.setItem("token", JSON.stringify(this.token));

    const user = await $http.get(`v1/auth/whoami/`);
    this.user = user.data;
    localStorage.setItem("user", JSON.stringify(this.user));

    // redirect to previous url or default to home page
    return this.returnUrl || "/";
  }

  async function refreshToken() {
    try {
      const tokenRes = await $http.post(`v1/auth/token/refresh/`, {
        refresh: this.token.refresh,
      });
      this.token = tokenRes.data;
      localStorage.setItem("token", JSON.stringify(this.token));

      const user = await $http.get(`v1/auth/whoami/`);
      this.user = user.data;
      localStorage.setItem("user", JSON.stringify(this.user));

      return this.token;
    } catch (err) {
      this.logout();
      return {};
    }
  }

  async function changePassword(body) {
    const res = await $http.patch(`v1/auth/change_password/`, body);
    return res.data;
  }

  async function logout() {
    this.user = null;
    this.token = null;
    await localStorage.removeItem("user");
    await localStorage.removeItem("token");
    await localStorage.removeItem("menu");
    return true;
  }

  function hasPermission(name) {
    return !(
      (this.user?.role?.permissions || []).filter((z) => z.name === name)
        .length === 0
    );
  }

  async function editUserPassword(body) {
    const res = await $http.patch(`v1/auth/whoami/change_password/`, body);
    return res.data;
  }

  return {
    user,
    token,
    returnUrl,
    loginWithMobile,
    changePassword,
    refreshToken,
    logout,
    hasPermission,
    editUserPassword,
  };
});
